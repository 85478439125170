<template>
  <collapse-card :model-value="true">
    <template #title>
      <span class="mr-2 text-sm font-medium">
        Attachments
      </span>
    </template>
    <template #cardContent>
      <div class="py-2 px-4">
        <!-- Submission Asset .. only on new collaboration -->
        <div
          v-if="assetsFromSubmission && assetsFromSubmission.length"
          class="flex flex-wrap"
        >
          <h6
            class="font-semibold text-black text-sm w-full"
          >
            Submission Attachments
          </h6>
          <div
            v-for="(uploadedFile, uFIndex) in assetsFromSubmission"
            :key="'submissionAssetFile' + uFIndex"
            class="w-1/2 py-4 pr-4"
          >
            <div class="flex">
              <div class="flex flex-col mr-4">
                <div class="h-28 w-28 p-1">
                  <img
                    class="m-auto max-h-full max-w-full"
                    :src="thumbPathSrcMap(uploadedFile)"
                  >
                </div>
              </div>

              <div class="flex flex-col flex-grow">
                <div class="text-black font-semibold text-sm my-1 max-w-xs bg-custom-gray-2 p-1 px-2 rounded-md truncate">
                  {{ uploadedFile.filename }}
                </div>
                <div
                  class="flex my-2 justify-start text-primary-red cursor-pointer"
                  @click="removeSubmissionAttachment(uploadedFile)"
                >
                  <base-svg
                    v-if="selectedUploadAsset?.assetId !== uploadedFile.assetId"
                    class="h-5 w-5 mr-1 inline-block  "
                    src="icons/cross.svg"
                    tag="span"
                  /> Remove
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Upload Form -->
        <div v-if="isCollaborationActive">
          <h6 class="font-semibold text-black text-sm">
            Files to Upload
          </h6>
          <!-- invisible file input -->
          <input
            ref="fileUploadInput"
            type="file"
            class="hidden"
            multiple
            @change.prevent="addNewFiles"
          >
          <div class="flex mb-3">
            <div class="rounded-sm border border-custom-gray-8 mr-3 w-full max-w-lg min-h-40 px-3 py-2 flex flex-wrap">
              <div
                v-for="(file, fileIndex) in newFilesList"
                :key="'new-file' + fileIndex"
                class="bg-custom-gray-5 text-black flex flex-col justify-center px-2 py-1 rounded mr-1 mb-1"
              >
                <div class="flex flex-col justify-center flex-grow">
                  <img
                    v-if="file.stagedFileUrl && file.file.type && ['image/jpeg','image/png','image/gif'].includes(file.file.type)"
                    :src="file.stagedFileUrl"
                    class="mx-auto h-12 w-auto max-w-xs">
                  <img
                    v-else
                    src="@/assets/images/upload/file.png"
                    class="mx-auto h-8 w-auto max-w-xs"
                  >
                </div>

                <div class="mx-auto mt-1 w-32 flex justify-center">
                  <div class="mr-1 truncate text-xs flex-grow">
                    {{ file.filename }}
                  </div>
                  <base-svg
                    class="h-3.5 w-3.5 text-primary-red inline-block hover:text-custom-gray-7 cursor-pointer align-middle flex-shrink-0"
                    src="icons/cross.svg"
                    tag="div"
                    @click="removeNewFile(fileIndex)"
                  />
                </div>
              </div>
            </div>
            <div class="flex flex-col">
              <base-button
                class="py-1 mb-3"
                variant="btn-primary"
                text="Add Files"
                :is-loading="__submissionLoadingTracker.isLoadingColabDocs"
                @click="openFileSelector()"
              />
              <base-button
                variant="btn-link"
                text="Clear All"
                @click="clearAllNewFiles()"
              />
            </div>
          </div>
          <div class="flex mb-6 justify-between w-full">
            <div v-if="__submissionLoadingTracker.isLoadingColabDocs">
              <base-svg
                class="h-4 w-4 mr-1 text-primary-red inline-block"
                src="icons/circleSpinner.svg"
                tag="span"
              />
              Starting file upload ...
            </div>
            <base-button
              v-else
              class="mr-4 py-1 whitespace-nowrap"
              text="Upload Files"
              type="button"
              variant="btn-primary"
              :disabled="newFilesList.length === 0"
              @click="initAttachmentUpload()"
            />
            <!-- <base-button
              v-if="uploadedFilesList.length > 0"
              variant="btn-primary ml-4"
              text="Refresh All"
              type="button"
              :is-loading="isRefreshLoading"
              @click="refreshAllAttachments()"
            /> -->
          </div>
        </div>

        <!-- Uploaded files section -->
        <div
          v-if="isFetchingUploadedFiles"
          class="w-full text-center mx-auto p-2"
        >
          <base-svg
            class="h-4 w-4 mr-1 text-primary-red inline-block"
            src="icons/circleSpinner.svg"
            tag="span"
          />
          Loading ...
        </div>
        <div
          v-else
          class="flex flex-wrap"
        >
          <div
            v-for="(uploadedFile, uFIndex) in uploadedFilesList"
            :key="'uploadedFile' + uFIndex"
            class="w-1/2 py-4 pr-4"
          >
            <div class="flex">
              <div class="flex flex-col mr-4">
                <div class="h-28 w-28 p-1">
                  <img
                    class="m-auto max-h-full max-w-full"
                    :src="thumbPathSrcMap(uploadedFile)"
                  >
                </div>
                <div
                  v-if="isClaimedByUser()"
                  class="flex justify-center my-2"
                >
                  <base-svg
                    v-if="selectedUploadAsset?.assetId == uploadedFile.assetId && uploadedFile.isUploaded === 'Y'"
                    class="h-4 w-4 mx-2 inline-block text-primary-red"
                    src="icons/circleSpinner.svg"
                    tag="span"
                  />
                  <base-svg
                    v-if="selectedUploadAsset?.assetId !== uploadedFile.assetId && uploadedFile.isUploaded === 'Y'"
                    class="h-4 w-4 mx-2 inline-block text-primary-red cursor-pointer"
                    src="icons/download.svg"
                    tag="span"
                    @click="downloadUploadedFile(uploadedFile)"
                  />
                  <base-svg
                    v-if="isCollaborationActive && selectedUploadAsset?.assetId !== uploadedFile.assetId && uploadedFile.createdUser === userId"
                    class="h-4 w-4 mx-2 inline-block text-primary-red cursor-pointer"
                    src="icons/cross.svg"
                    tag="span"
                    @click="removeCollaborationAttachment(uploadedFile)"
                  />
                </div>
              </div>

              <div class="flex flex-col flex-grow">
                <div class="text-black font-semibold text-sm mt-2 mb-2 max-w-xs bg-red-100 p-1 rounded-md truncate">
                  {{ uploadedFile.filename }}
                </div>
                <div class="flex mb-1 justify-between">
                  <div class="text-black text-sm mb-1 mr-40 whitespace-nowrap">
                    <span class="mr-3">Uploaded On:</span>
                    <span class="font-medium">{{ formatDate(uploadedFile.creationDate, 'MM/DD/YYYY', true) }}</span>
                  </div>
                </div>
                <div class="flex mb-1 justify-between">
                  <div class="text-black text-sm mr-20 whitespace-nowrap">
                    <span class="mr-3">Uploaded By:</span>
                    <span class="font-medium">{{ uploadedFile.createdUser }}</span>
                  </div>
                </div>
                <div class="text-black text-sm mb-1">
                  <span class="mr-3">Uploaded At:</span>
                  <span class="font-medium">{{ getSubmissionStageName(uploadedFile.stage) }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </collapse-card>
</template>

<script>
import { ref, reactive, watch, defineAsyncComponent, computed } from 'vue';
import useSubmissionForm from '@/components/brand-assurance-submission-form/submissionForm.js';
import useSubmissionLoadTracker from '@/components/brand-assurance-submission-form/submissionLoadTracker.js';

import { useStore } from 'vuex';
import { formatDate, formatFilename } from '@/helpers/util';
import useCollaborationForm from './collaborationForm';
import useToastNotifications from '@/hooks/toastNotifications.js';
import { SUCCESS, WARNING, ERROR, ATTACHEMENT_DOWNLOAD_FAILED, ATTACHEMENT_DOWNLOADED_SUCCESSFULLY } from '@/constants/alerts.js';
import useUploadStatusPopUp from '@/components/brand-assurance-submission-form/uploadStatusPopUp';

export default {
    name: 'CollaborationCommonUploads',

    components: {
        CollapseCard: defineAsyncComponent(() => import('@/components/CollapseCard.vue')),
        BaseButton: defineAsyncComponent(() => import('@/components/generic-components/BaseButton.vue')),
        BaseSvg: defineAsyncComponent(() => import('@/components/generic-components/BaseSvg.vue'))
    },

    setup (props, { emit }) {
        // upload file logic
        const store = useStore();
        const { collaborationForm, isCollaborationActive, updateSubmissionAssets, updateCollaborationAssets } = useCollaborationForm();
        const { notificationsStack } = useToastNotifications();
        const { pushFilesForAssetPlaceholders, isFetchingUploadedFiles } = useUploadStatusPopUp();

        const { submissionForm, submissionFormMode } = useSubmissionForm();
        const userId = computed(() => store.getters['auth/getUserId']);
        const isClaimedByUser = () => {
            return submissionForm.assignUser === userId.value || submissionFormMode.value === 'NEW';
        };

        const fileUploadInput = ref(null);
        const newFilesList = ref([]);

        const addNewFiles = (event) => {
            const { files } = event.target;
            const MAX_ALLOWED_FILE_SIZE = (95 * 1024 * 1024 * 1024); // 95GB
            for (let i = 0; i < files.length; i++) {

                let filename = formatFilename(files[i].name);
                // regex to check on file upload..
                const specialChars = /[`!@#$%^&*()+=[\]{};':"\\|,<>/?~]/;
                const nonEnglishChar = /[^\x00-\x7F]+/;

                const nonEnglishCharExists = nonEnglishChar.test(filename);
                const specialCharsExists = specialChars.test(filename);

                if (specialCharsExists || nonEnglishCharExists) {
                    notificationsStack.value.push({
                        type: WARNING,
                        message: `File name can contain only Alphabets, Numbers, Period, Underscore and Hyphens: ${filename}`
                    });
                } else if (files[i].size && files[i].size > MAX_ALLOWED_FILE_SIZE) {
                    notificationsStack.value.push({
                        type: WARNING,
                        message: `File size exceeds the maximum allowed size: ${filename}`
                    });
                } else if (uploadedFilesList?.value.some(tmpFile => formatFilename(tmpFile.filename).toLowerCase() === filename.toLowerCase() && tmpFile.isDelete === 'N')) {
                    notificationsStack.value.push({
                        type: WARNING,
                        message: `Attachment already exists with filename: ${filename}`
                    });
                } else if (files[i].size < 1) {
                    notificationsStack.value.push({
                        type: WARNING,
                        message: `File size cannot be zero for : ${filename}`
                    });
                } else if (newFilesList.value.some(newFile => formatFilename(newFile.file.name).toLowerCase() === filename.toLowerCase())) {
                    const tmpMsg = `Skipping duplicate file .. ${filename}`;
                    console.log(tmpMsg);
                    notificationsStack.value.push({
                        type: WARNING,
                        message: tmpMsg
                    });
                } else {
                    const stagedFileUrl = URL.createObjectURL(files[i]);

                    if (!filename.includes('.')) filename = `${filename}.bin`;

                    newFilesList.value.push({
                        file: files[i],
                        assetType: null,
                        filename,
                        stagedFileUrl
                    });
                }
            }
            event.target.value = null;
        };
        const removeNewFile = (index) => {
            newFilesList.value.splice(index, 1);
        };
        const openFileSelector = () => {
            fileUploadInput.value.click();
        };
        const clearAllNewFiles = () => {
            newFilesList.value = [];
        };

        const uploadDataFromStore = computed(() => store.getters['upload/getUploadData']);

        const { updateSubmissionLoadingTracker } = useSubmissionLoadTracker();
        const __submissionLoadingTracker = reactive({ isLoadingColabDocs: false });

        const initAttachmentUpload = async () => {
            try {
                __submissionLoadingTracker.isLoadingColabDocs = true;
                const filePaths = newFilesList.value.map(e => {
                    e.filename = e.filename.toLowerCase();
                    return `${e.stagedFileUrl}/${e.filename}`;
                });
                const fileSizes = newFilesList.value.map(e => e.file.size);

                await store.dispatch('upload/uploadFiles', {
                    payload: {
                        fileSizes,
                        filePaths,
                        attachmentData: {
                            submissionId: collaborationForm.collaborationId,
                            stage: collaborationForm.submission.currentStage || 1,
                            step: collaborationForm.submission.currentStep || 1,
                            assetType: 'COLLABORATION'
                        }
                    }
                });

                if (!collaborationForm.assets) collaborationForm.assets = [];
                uploadDataFromStore.value.forEach((file, i) => {
                  collaborationForm.assets?.push({
                    assetId: file.objectId,
                    filename: file.filename,
                    isDelete: 'N',
                    isUploaded: 'N',
                    assetType: 'COLLABORATION'
                  })
                });
                await pushFilesForAssetPlaceholders(newFilesList.value);
            } catch (err) {
                console.error(err);
                notificationsStack.value.push({
                    type: ERROR,
                    message: 'Problem in creating the attachment.'
                });
            } finally {
                __submissionLoadingTracker.isLoadingColabDocs = false;
                clearAllNewFiles();
            }
        };

        const uploadedFilesList = computed(() => {
            return collaborationForm.assets && collaborationForm.assets.length
                ? collaborationForm.assets.filter(asset => asset.assetType === 'COLLABORATION' && asset.isDelete === 'N') : [];
        });

        const downloadUploadedFile = async (asset) => {
            try {
                selectedUploadAsset.value = asset;
                const getPresignedURL = await store.dispatch('upload/getDownloadAttachmentURL', {
                    params: {
                    // DIRECT-S3-DL
                        id: asset.fileId,
                        filename: asset.filename,
                        isSubmissionAttachment: true
                    }
                });

                if (!getPresignedURL?.presignedURL) throw new Error('Download URL is not available.')
                const windowOpened = window.open(getPresignedURL.presignedURL);
                if (!windowOpened) {
                    notificationsStack.value.push({
                        type: ERROR,
                        message: 'A popup blocker may be preventing the application. Please add this site to your exception list in order to upload/ download.'
                    });
                } else {
                    notificationsStack.value.push({
                        type: SUCCESS,
                        message: ATTACHEMENT_DOWNLOADED_SUCCESSFULLY
                    });
                }
            } catch (err) {
                console.error(err);
                notificationsStack.value.push({
                    type: ERROR,
                    message: `${ATTACHEMENT_DOWNLOAD_FAILED}. ${err}`
                });
            } finally {
                selectedUploadAsset.value = null;
            }
        };

        // confirm delete search modal logic
        const selectedUploadAsset = ref(null);

        const thumbPathSrcMap = ({ filename, thumbnailUrl, isUploaded }) => {
            // console.log(`called for ${filename}`)
            if (isUploaded !== 'Y') return require('@/assets/images/upload/uploadFileImage.png');

            const fileExt = filename.split('.').pop();
            if (fileExt) switch (fileExt.toLowerCase()) {
            case 'jpg':
            case 'jpeg':
            case 'heic':
            case 'png':
            case 'psd':
            case 'eps':
            case 'gif':
            case 'tif':
            case 'tiff':
                if (thumbnailUrl) return thumbnailUrl;
                else return require('@/assets/images/upload/file.png');
            case 'pdf':
                if (thumbnailUrl) return thumbnailUrl;
                return require('@/assets/images/upload/pdf.png');
            case 'zip':
                return require('@/assets/images/upload/zip.png');

            default:
                return require('@/assets/images/upload/file.png');
            }

            return require('@/assets/images/upload/file.png');
        };

        const submissionStages = computed(() => store.getters['baSubmissions/getSubmissionStages']);
        const getSubmissionStageName = (stageKey) => {
            if (!submissionStages?.value) return '';
            const tmp = submissionStages.value.find(s => s.stageKey === stageKey);
            return tmp?.stageName || '[ Processing... ]';
        };

        const assetsFromSubmission = computed(() =>
            collaborationForm.submission.assets && collaborationForm.submission.assets.length
                ? collaborationForm.submission.assets.filter(asset =>
                    asset.isUploaded === 'Y' &&
              asset.isDelete === 'N' &&
              asset.assetType === 'SUBMISSION'
                )
                : []
        );

        const removeSubmissionAttachment = (asset) => {
            updateSubmissionAssets(collaborationForm.submission.assets.filter(a => a.assetId !== asset.assetId));
        };

        const removeCollaborationAttachment = (asset) => {
            updateCollaborationAssets(
                collaborationForm.assets.map(a => {
                    if (a.assetId === asset.assetId) return { ...a, isDelete: 'Y' };
                    else return a;
                })
            );
        };

        watch(
            __submissionLoadingTracker,
            () => {
                updateSubmissionLoadingTracker({ ...__submissionLoadingTracker });
            },
            { deep: true, immediate: true }
        );

        return {
            // upload file
            formatDate,
            removeNewFile,
            openFileSelector,
            newFilesList,
            addNewFiles,
            clearAllNewFiles,
            initAttachmentUpload,
            fileUploadInput,
            // refreshAllAttachments,

            uploadedFilesList,
            assetsFromSubmission,
            downloadUploadedFile,
            userId,

            selectedUploadAsset,

            thumbPathSrcMap,
            isFetchingUploadedFiles,
            getSubmissionStageName,
            isClaimedByUser,
            isCollaborationActive,
            removeSubmissionAttachment,
            removeCollaborationAttachment,
            __submissionLoadingTracker
        };
    }

};
</script>
